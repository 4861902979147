import React from 'react'

import styles from './Items.module.scss'

const Items = ({ children }) => {
  return <div className={styles.items}>{children}</div>
}

const Item = ({ children }) => {
  return <div className={styles.item}>{children}</div>
}

const ItemTitle = ({ children }) => {
  return <h5 className={styles.itemTitle}>{children}</h5>
}

export { Items, Item, ItemTitle }
