import React from 'react'
import cn from 'classnames'

import styles from './SideBySide.module.scss'

const SideBySide = ({ children, reverse }) => {
  return (
    <div className={cn(styles.sideBySide, { [styles.reverse]: reverse })}>
      {children}
    </div>
  )
}

const SideBySideText = ({ children }) => {
  return <div className={styles.sideBySideText}>{children}</div>
}

const SideBySideImg = ({ src, alt }) => {
  return (
    <div className={styles.sideBySideImg}>
      <img src={src} alt={alt} />
    </div>
  )
}

const SideBySideTitle = ({ children }) => {
  return <h3 className={styles.sideBySideTitle}>{children}</h3>
}

export { SideBySide, SideBySideText, SideBySideImg, SideBySideTitle }
