import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'

import styles from './CircleIcon.module.scss'

const CircleIcon = ({ icon, color }) => {
  const className = cn(styles.icon, [styles[color]])

  return (
    <div className={className}>
      <FontAwesomeIcon icon={icon} size="1x" />
    </div>
  )
}

const CircleIconGroup = ({ icon, color, children }) => {
  return (
    <div className={styles.circleIconGroup}>
      <CircleIcon icon={icon} color={color} />
      <div>{children}</div>
    </div>
  )
}

const CircleIconGroupTitle = ({ children }) => {
  return <h5 className={styles.circleIconGroupTitle}>{children}</h5>
}

export default CircleIcon
export { CircleIconGroup, CircleIconGroupTitle }
