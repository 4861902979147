import React from 'react'
import cn from 'classnames'

import styles from './CtaCurve.module.scss'

const CtaCurve = ({ children, white }) => {
  const className = cn(styles.ctaCurve, { [styles.white]: white })
  return (
    <div className={className}>
      <span className={styles.curve} />
      <div className={styles.cta}>{children}</div>
    </div>
  )
}

const CtaCurveTitle = ({ children }) => {
  return <h4 className={styles.ctaCurveTitle}>{children}</h4>
}

export { CtaCurve, CtaCurveTitle }
