import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './SquircleIcon.module.scss'

const SquircleIcon = props => {
  const { color, icon } = props

  const className = cn(styles.squircle, [styles[color]])

  return (
    <div className={className}>
      <FontAwesomeIcon icon={icon} size="1x" />
    </div>
  )
}

export default SquircleIcon
