import React from 'react'
import { Link } from 'gatsby'
import {
  faMobile,
  faFileAlt,
  faPhone,
  faCrown,
  faHandshake,
  faGraduationCap,
  faMoneyCheck,
  faFileContract,
  faPoundSign,
  faFlask,
  faSack,
  faShieldAlt,
} from '@fortawesome/pro-light-svg-icons'

import dayToDayImg from '../../images/svg/how-it-works/day-to-day.svg'
import taxYearImg from '../../images/svg/how-it-works/tax-year.svg'
import yearEndImg from '../../images/svg/how-it-works/year-end.svg'
import standalone from '../../images/svg/how-it-works/standalone.svg'
import specialised from '../../images/svg/how-it-works/specialised.svg'

import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import TimelineStep from '../../components/Timeline'
import Section from '../../components/Section'
import SquircleIcon from '../../components/SquircleIcon'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { Intro, IntroTitle, IntroText } from '../../components/Intro'
import { Items, Item, ItemTitle } from '../../components/Items'
import { CtaCurve, CtaCurveTitle } from '../../components/CtaCurve'
import { PricingPlanTable, PricingPlan } from '../../components/PricingPlan'
import {
  CircleIconGroup,
  CircleIconGroupTitle,
} from '../../components/CircleIcon'
import {
  SideBySide,
  SideBySideImg,
  SideBySideText,
  SideBySideTitle,
} from '../../components/SideBySide'

const SoleTraders = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Sole trader accountants" />
      <TitleContainer>
        <Title>Accounting for sole traders</Title>
        <TitleIntro>
          Our sole trader accountants take care of all things tax so you can
          focus on your own work. We give you total peace of mind that your
          accounts and tax returns are prepared correctly whilst ensuring you're
          not paying a penny more in tax than you need to.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <Intro>
          <IntroTitle>How does it work?</IntroTitle>
          <IntroText>
            Keeping your books ready for when it's time to file your taxes
            couldn't be quicker or easier. All you have to do is use our mobile
            app to send us your income and expenses and we'll take care of
            everything for you.
          </IntroText>
        </Intro>
        <TimelineStep
          img={dayToDayImg}
          imgAlt="Day-to-day"
          count="01"
          heading="Day-to-day updates"
          lineEnd
        >
          <ul className="bullet-list">
            <li>
              Take photos of your expenses using our mobile app to send them
              directly to your accountant.
            </li>
            <li>
              Connect your bank accounts to our bank feed system and we’ll
              automatically download your bank statements.
            </li>
            <li>
              Create professional looking invoices to send to your customers
              using our invoicing tool.
            </li>
          </ul>
        </TimelineStep>
        <TimelineStep
          img={taxYearImg}
          imgAlt="Throughout the tax year"
          heading="Throughout the tax year"
          count="02"
          lineStart
          lineEnd
          reverse
        >
          <ul className="bullet-list">
            <li>
              Our tax team prepares your actual and estimated tax bills that you
              can view and manage before they arrive.
            </li>
            <li>
              Your sole trader accountant will be available to answer any
              questions you may have.
            </li>
            <li>
              We'll carry out routine checks to see if your tax bills are
              estimated to be more than normal.
            </li>
          </ul>
        </TimelineStep>
        <TimelineStep
          img={yearEndImg}
          imgAlt="End of year"
          count="03"
          heading="Finalising your year end"
          lineStart
        >
          <ul className="bullet-list">
            <li>
              Your accounts are prepared by qualified accountants to send to
              mortgage advisers, banks and other stakeholders.
            </li>
            <li>
              We prepare your tax return to comply with tax laws whilst claiming
              all available reliefs and expenses.
            </li>
            <li>
              All legal filings to HM Revenue & Customs (HMRC) are submitted on
              your behalf.
            </li>
          </ul>
        </TimelineStep>
      </Section>
      <Section color="gray" bubbles>
        <Intro>
          <IntroTitle>What is your pricing?</IntroTitle>
          <IntroText>
            Sole traders can choose our small business accounting service to get
            full support and unlimited advice for a fixed monthly or annual fee.
            Alternatively our sole trader accountants can just prepare your
            annual tax return for a one-off competitive fee.
          </IntroText>
        </Intro>
        <PricingPlanTable>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Monthly"
            planPrice="£25.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Sole trader accounts',
              'Self-assessment tax return',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Annually"
            planPrice="£306.00"
            planPeriod="+ VAT per year"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Sole trader accounts',
              'Self-assessment tax return',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Standalone service"
            planName="Tax return"
            planPrice="£99.00"
            planPeriod="+ VAT per year"
            planStandardServices={['Self-assessment tax return']}
            planAddOnServices={['Small business accounting']}
          >
            <Link className="btn btn-primary d-block mx-auto" to="/call-back">
              Request a call back
            </Link>
          </PricingPlan>
        </PricingPlanTable>
      </Section>
      <Section>
        <Intro>
          <IntroTitle>What's included?</IntroTitle>
          <IntroText>
            Running your own business can be difficult and time consuming, which
            is why as your sole trader accountants we give you all of the below
            services as standard when you sign up to our small business
            accounting service.
          </IntroText>
        </Intro>
        <Items>
          <Item>
            <SquircleIcon color="purple" icon={faMobile} />
            <ItemTitle>Market-leading software</ItemTitle>
            <p>
              Amazing software that handles VAT returns, invoicing, expenses,
              bank statements and any other aspect of your accounts. Your
              dedicated accountant will use this software to deliver a
              personalised service for you and your business.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="green" icon={faFileAlt} />
            <ItemTitle>Tax registrations</ItemTitle>
            <p>
              It can be difficult to know what taxes apply to your business.
              With taxes such as VAT, PAYE and Corporation Tax all having their
              own rules it's easy to make a mistake. Our sole trader accountants
              ensure you're registered for all taxes necessary.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="orange" icon={faPhone} />
            <ItemTitle>Unlimited advice</ItemTitle>
            <p>
              Our team are always on hand to answer any questions you may have
              and to guide you in the right direction. We'll tell you what
              expenses you can claim for, what you can do to reduce your tax
              bills, how much money you're making and so much more.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="yellow" icon={faCrown} />
            <ItemTitle>HMRC representation</ItemTitle>
            <p>
              Dealing with HMRC can be intimidating and stressful. Our experts
              handle all contact with HMRC on your behalf by approaching each
              scenario with great care and ensuring your taxpayer rights are
              upheld.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="pink" icon={faHandshake} />
            <ItemTitle>Partner network</ItemTitle>
            <p>
              We've partnered with some truly great businesses to help our
              clients get the support and services they need. From bank accounts
              to insurance, business loans to legal help, you have access to
              them all.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="blue" icon={faGraduationCap} />
            <ItemTitle>Training</ItemTitle>
            <p>
              Keeping an eye on the numbers is a key ingredient to success. Our
              sole trader accountants will work with you to help you understand
              your business's performance and other useful information.
            </p>
          </Item>
        </Items>
      </Section>
      <Section color="gray">
        <SideBySide reverse>
          <SideBySideText>
            <SideBySideTitle>Don't need the full service?</SideBySideTitle>
            <p>
              We offer a standalone tax return service for those sole traders
              that just need a little helping hand.
            </p>
            <div className="mt-6">
              <CircleIconGroup icon={faMoneyCheck} color="primary">
                <CircleIconGroupTitle>Payroll & pension</CircleIconGroupTitle>
                <p>
                  Taxes and deductions calculated and payslips prepared for your
                  staff.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faFileContract} color="orange">
                <CircleIconGroupTitle>
                  Catch up year end accounts
                </CircleIconGroupTitle>
                <p>
                  Preparing accounts to meet deadlines and to get your books
                  back up-to-date.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faPoundSign} color="green">
                <CircleIconGroupTitle>
                  Personal tax returns
                </CircleIconGroupTitle>
                <p>
                  Completing and submitting your personal tax return to report
                  your income and capital gains.
                </p>
              </CircleIconGroup>
            </div>
            <Link
              className="btn btn-primary-light"
              to="/pricing/standalone-services"
            >
              Learn more
            </Link>
          </SideBySideText>
          <SideBySideImg src={standalone} alt="standalone services" />
        </SideBySide>
        <SideBySide>
          <SideBySideImg src={specialised} alt="specialised services" />
          <SideBySideText>
            <SideBySideTitle>Need expert help?</SideBySideTitle>
            <p>
              Our specialised services carried out by experienced professionals
              can make a real impact to you and your business.
            </p>
            <div className="mt-6">
              <CircleIconGroup icon={faFlask} color="primary">
                <CircleIconGroupTitle>
                  Research and Development (R&D)
                </CircleIconGroupTitle>
                <p>
                  Assessing, preparing and submitting detailed R&D tax credit
                  claims on a no-win no-fee basis.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faSack} color="orange">
                <CircleIconGroupTitle>Fundraising</CircleIconGroupTitle>
                <p>
                  Through our network of partners we can help raise funds for
                  your business to grow or ease cashflow.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faShieldAlt} color="green">
                <CircleIconGroupTitle>Tax investigatons</CircleIconGroupTitle>
                <p>
                  We can take full control of a tax investigation to minimise
                  the risk of big bills.
                </p>
              </CircleIconGroup>
            </div>
            <Link
              className="btn btn-green-light"
              to="/pricing/standalone-services"
            >
              Get in touch
            </Link>
          </SideBySideText>
        </SideBySide>
      </Section>
      <CtaCurve>
        <CtaCurveTitle>Have any questions?</CtaCurveTitle>
        <p>We can call you anytime between 9am - 5pm Monday to Friday.</p>
        <Link className="btn btn-primary-invert" to="/call-back">
          Request a call back
        </Link>
      </CtaCurve>
    </SiteLayout>
  )
}

export default SoleTraders

/*
<div className={styles.bg}>
  <span className={styles.one} />
  <span className={styles.two} />
  <span className={styles.three} />
  <span className={styles.four} />
</div>
*/
